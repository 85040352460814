// React
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
// Redux
import { connect } from 'react-redux';
class Firewall extends Component {
  static propTypes = {
    userReducer: PropTypes.object,
    children: PropTypes.element,
  };
  render() {
    if (this.props.userReducer.isLogged && this.props.userReducer.loggedAt + 3600000 > Date.now()) {
      return this.props.children;
    }
    return <Redirect to="/logout" />;
  }
}
export const mapStateToProps = state => ({
  userReducer: state.userReducer,
});
export default connect(mapStateToProps)(Firewall);
