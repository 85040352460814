class Configuration {
  setApiUrl(baseUrl) {
    this.baseUrl = baseUrl;
  }

  getApiUrl() {
    return this.baseUrl;
  }

  setAccessToken(accessToken) {
    this.accessToken = accessToken;
  }

  getAccessToken() {
    return this.accessToken;
  }
}

export default new Configuration();
