// Redux
import { createStore, combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// Reducers
import userReducer from './redux/UserDuck';
import explorerReducer from './redux/ExplorerDuck';

const rootReducer = combineReducers({
  userReducer,
  explorerReducer,
});

const persistConfig = {
  key: 'root',
  storage,
};

export default createStore(persistReducer(persistConfig, rootReducer));
