import 'react-app-polyfill/ie9'; // For IE 9-11 support
import 'react-app-polyfill/ie11'; // For IE 11 support
import './polyfill';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import Loadable from 'react-loadable';
import './App.scss';
import { Configuration } from './tools/http-connector';
import Firewall from './services/Firewall';
import config from './config';

// Redux
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
// Components
import Store from './store';

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;
// Containers
const DefaultLayout = Loadable({
  loader: () => import('./containers/DefaultLayoutClassic'),
  loading
});

// Pages
const Login = Loadable({
  loader: () => import('./modules/Security/Login'),
  loading
});
// Pages
const Logout = Loadable({
  loader: () => import('./modules/Security/Logout'),
  loading
});
// Inscription
const Register = Loadable({
  loader: () => import('./modules/Classic/Inscription/Inscription'),
  loading
});
// Tunnel d'inscription
const InscriptionTunnel = Loadable({
  loader: () => import('./modules/Classic/Inscription/Inscription/InscriptionTunnel'),
  loading
});

// Tunnel d'inscription
const RegistrationForm = Loadable({
  loader: () => import('./modules/Registration/RegistrationForm'),
  loading
});

//finalisation thanks
const ThanksFinalisation = Loadable({
  loader: () => import('./blocks/ThanksFinalisation'),
  loading
});

//finalisation thanks
const ThanksValidatePreInscription = Loadable({
  loader: () => import('./blocks/ThanksValidatePreInscription'),
  loading
});
// Configuration
Configuration.setApiUrl(config.apiUrl);
ReactDOM.render(
  <Provider store={Store}>
    <PersistGate loading={null} persistor={persistStore(
      Store,
      null,
      () => {
        const { accessToken } = Store.getState().userReducer;
        if (accessToken !== null) {
          Configuration.setAccessToken(accessToken);
        }
      },
    )}>
      <BrowserRouter>
        <Switch>
          {/* <Route exact path="/register/:token/:product?/:agenceId?/:stageId?/:issoldes?/:iscode?/:iseval?" component={Register} /> */}
          <Route exact path="/register/:token/:product?/:isnewoffer?/:agenceId?/:stageId?/:issoldes?/:iscode?" component={Register} />
          <Route exact path="/login/:stageId?" component={Login} />
          <Route exact path="/wp/login/:email?/:code?/:issoldes?/:iscode?" component={Login} />
          <Route exact path="/auto/login/:token?/:iseval?" component={Login} />
          <Route exact path="/logout" component={Logout} />
          <Route exact path="/tunnel-inscription/:formationSelect?" component={InscriptionTunnel} />
          <Route path="/pre-inscription/:isnewoffer?" name="Inscription register" component={RegistrationForm} />
          <Route path="/redirectToInscription/:link?" name="final inscription" component={ThanksFinalisation} />
          <Route path="/thanks-valdate-pre-inscription/:link?" name="final validate inscription" component={ThanksValidatePreInscription} />
          <Firewall>
            <Route path="/" name="Home" component={DefaultLayout} />
          </Firewall>
        </Switch>
      </BrowserRouter>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
