import HCConfigurationError from '../errors/HCConfigurationError';
import Configuration from '../instances/Configuration';
import axios from 'axios';

// HTTP Calls
const Connector = {
  request: function request(METHOD_TYPE, URL, DATA = null) {
    const METHOD = METHOD_TYPE.toUpperCase();
    let FINAL_URL = URL;

    const fetchOptions = {
      method: METHOD,
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    };

    const accessToken = Configuration.getAccessToken();
    if (accessToken) {
      fetchOptions.headers = {
        ...fetchOptions.headers,
        'X-Auth-Token': accessToken,
      };
    }

    if (DATA !== null && (METHOD === 'POST' || METHOD === 'PUT' || METHOD === 'PATCH')) {
      fetchOptions.body = JSON.stringify(DATA);
    }

    return fetch(FINAL_URL, fetchOptions)
      .then(response => response.json())
      .catch(() => false);
  },
  apiRequest: function apiRequest(METHOD, URL, DATA = null) {
    const apiBase = Configuration.getApiUrl();
    const accessToken = Configuration.getAccessToken();

    
    if (apiBase === undefined) {
      throw new HCConfigurationError('The apiBaseUrl is not set.');
    }
    return this.request(METHOD, apiBase + URL, DATA);
  },
  apiCNGRequest: function apiCNGRequest(METHOD, URL, DATA = null) {

      let FINAL_URL = 'https://codengo.bureauveritas.fr/api/v1/' + URL;
      const fetchOptions = {
          method: METHOD.toUpperCase(),
          headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json',
              'Access-Control-Allow-Origin': '*',
              'X-Auth-Partner-key': 'MTI6MmI3ZWI5ZTUtZTY2NS00NTYyLWEwMmUtNzgwNTVhZDQ5MTM3',
              // 'X-Auth-Partner-key': 'MTE6NTZkYTA4ZDYtMDMwYS00OWE0LWE1OGEtMTA3MjUwN2IzMTYw',
          },
      };

      if (DATA !== null && (METHOD === 'POST' || METHOD === 'PUT' || METHOD === 'PATCH')) {
          fetchOptions.body = JSON.stringify(DATA);
      }
      return fetch(FINAL_URL, fetchOptions)
          .then(response => response.json())
          .catch(() => false);
  },
  apiCNGFileRequest: function apiCNGRequest(METHOD, URL) {
      let FINAL_URL = 'https://codengo.bureauveritas.fr/api/v1/' + URL;
      const fetchOptions = {
          method: METHOD.toUpperCase(),
          responseType: "arraybuffer",
          headers: {
              // 'Content-Type': 'application/json',
              'Content-Type': 'application/pdf',
              // 'Content-Disposition' : 'attachment; filename="convocation.pdf"',
              'X-Auth-Partner-key': 'MTI6MmI3ZWI5ZTUtZTY2NS00NTYyLWEwMmUtNzgwNTVhZDQ5MTM3',
              // 'X-Auth-Partner-key': 'MTE6NTZkYTA4ZDYtMDMwYS00OWE0LWE1OGEtMTA3MjUwN2IzMTYw',
          },
      };
      // return fetch(FINAL_URL, fetchOptions)
      //     .then(response => response)
      //     .catch((error) => error);
      return axios(FINAL_URL, {
          method: METHOD,
          headers: {
              'X-Auth-Partner-key': 'MTE6NTZkYTA4ZDYtMDMwYS00OWE0LWE1OGEtMTA3MjUwN2IzMTYw',
          },
          responseType: 'blob' //Force to receive data in a Blob Format
      })

  },
  dataRequest: function request(METHOD_TYPE, URL, DATA = null) {
    const METHOD = METHOD_TYPE.toUpperCase();
    let FINAL_URL = URL;

    const fetchOptions = {
      method: METHOD,
      headers: {
        'Content-Type': 'multipart/form-data',
        'Accept': '*/*',
        'Access-Control-Allow-Origin': '*',
      },
    };

    const accessToken = Configuration.getAccessToken();
    if (accessToken) {
      fetchOptions.headers = {
        ...fetchOptions.headers,
        'X-Auth-Token': accessToken,
      };
    }

    if (DATA !== null && (METHOD === 'POST' || METHOD === 'PUT' || METHOD === 'PATCH')) {
      fetchOptions.body = DATA;
    }

    return fetch(FINAL_URL, fetchOptions)
      .then(response => response.json())
      .catch(() => false);
  },
  apiDataRequest: function apiRequest(METHOD, URL, DATA = null) {
    const apiBase = Configuration.getApiUrl();

    if (apiBase === undefined) {
      throw new HCConfigurationError('The apiBaseUrl is not set.');
    }

    return this.dataRequest(METHOD, apiBase + URL, DATA);
  },
  apiPostFile: function apiPostFile(URL, DATA) {
    return new Promise((resolve, reject) => {
      const req = new XMLHttpRequest();

      req.onreadystatechange = () => {
        if (req.readyState === 4) {
          resolve(req.response);
        }
      };

      req.open("POST", URL);

      const accessToken = Configuration.getAccessToken();
      if (accessToken) {
        req.setRequestHeader('X-Auth-Token', accessToken);
      }
      req.send(DATA);
    });
  },
  apiPostFileRequest: function apiRequest(URL, DATA = null) {
    const apiBase = Configuration.getApiUrl();

    if (apiBase === undefined) {
      throw new HCConfigurationError('The apiBaseUrl is not set.');
    }

    return this.apiPostFile(apiBase + URL, DATA);
  },

  /* send documents */
  requestSendDocument: async function requestSendDocument(METHOD_TYPE, URI, DATA = null) {
    const accessToken = Configuration.getAccessToken();
    const apiBase = Configuration.getApiUrl();
    if (apiBase === undefined) {
      throw new HCConfigurationError('The apiBaseUrl is not set.');
    }
    
    const method_type = METHOD_TYPE.toLowerCase(),
        url = apiBase + URI;

    const formData = new FormData();
    formData.append('file', DATA.file)
    formData.append('name', DATA.name)
    //formData = JSON.stringify(formData)

    const options = {
        method: method_type,
        url: url,
        data: formData,
        headers: {
            'X-Auth-Token': accessToken,
            'Content-Type': `multipart/form-data; boundary=${formData.boundary}`,
        }
    };
    // send the request
    return axios(options);
    //*/
},
};

export default Connector;
