/**
 * Author: SGAT Technologies
 * https://www.sgat-technologies.com
 */

class HCConfigurationError {
  constructor(error) {
    this.error = error;
  }
}

export default HCConfigurationError;
