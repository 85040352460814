// Actions
const LOGIN = "user/LOGIN";
const LOGOUT = "user/LOGOUT";
const UPDATE = "user/UPDATE";
const UPDATE_GENERAL_CATEGORY = "user/UPDATE_GENERAL_CATEGORY";
const SET_ACCESS_TOKEN = "user/SET_ACCESS_TOKEN";
const SET_REFRESH_TOKEN = "user/SET_REFRESH_TOKEN";
const SET_REGISTER_UPDATE = "user/SET_REGISTER_UPDATE";
const UPDATE_IDCUSTOMER = "user/UPDATE_IDCUSTOMER";
const UPDATE_FORMATION_CHOISIE = "user/UPDATE_FORMATION_CHOISIE";
const UPDATE_PANIER = "user/UPDATE_PANIER";
const IS_PANIER_PAYED = "user/IS_PANIER_PAYED";

// Initial state
export const initialState = {
  isLogged: false,
  user: null,
  accessToken: null,
  password: null,
  loggedAt: null,
  registerUpdate: null,
  generalCategory: null,
  idCustomer: null,
  formation_choisie: null,
  panier: null,
  isPanierPayed: false,
};

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        generalCategory: 'AUTO',
        isLogged: true,
        user: action.user,
        accessToken: action.accessToken,
        password: action.password,
        loggedAt: Date.now(),
        idCustomer: action.idCustomer,
        formation_choisie: action.formation_choisie,
        panier: action.panier,
        isPanierPayed: action.isPanierPayed,
      };
    case LOGOUT:
      return {
        ...state,
        isLogged: false,
        user: null,
        accessToken: null,
        password: null,
        loggedAt: null,
        generalCategory: null,
        idCustomer: null,
        formation_choisie: null,
        panier: null,
      };
    case UPDATE:
      return {
        ...state,
        user: action.user,
      };
    case UPDATE_IDCUSTOMER:
      return {
        ...state,
        idCustomer: action.idCustomer,
      };
    case UPDATE_FORMATION_CHOISIE:
      return {
        ...state,
        formation_choisie: action.formation_choisie,
      };
    case UPDATE_GENERAL_CATEGORY:
      return {
        ...state,
        generalCategory: action.generalCategory
      };
    case UPDATE_PANIER:
      return {
        ...state,
        panier: action.panier
      };
    case IS_PANIER_PAYED:
      return {
        ...state,
        isPanierPayed: action.isPanierPayed
      }
    case SET_ACCESS_TOKEN:
      return {
        ...state,
        accessToken: action.token
      };
    case SET_REFRESH_TOKEN:
      return {
        ...state,
        refreshToken: action.token
      };
    case SET_REGISTER_UPDATE:
      return {
        ...state,
        registerUpdate: action.registerUpdate
      };
    default:
      return state;
  }
}

// Action Creators
export function loginUser(user = {}, accessToken = null, password = null) {
  return {
    type: LOGIN,
    user,
    accessToken,
    password,
  };
}

export function logoutUser() {
  return {
    type: LOGOUT
  };
}

export function updateUser(user) {
  return {
    type: UPDATE,
    user
  };
}

export function updateIdCustomer(idCustomer) {
  return {
    type: UPDATE_IDCUSTOMER,
    idCustomer
  };
}

export function updateFormationChoisie(formation_choisie) {
  return {
    type: UPDATE_FORMATION_CHOISIE,
    formation_choisie
  };
}

export function updateGeneralCategory(generalCategory) {
  return {
    type: UPDATE_GENERAL_CATEGORY,
    generalCategory
  };
}

export function updatePanier(panier) {
  return {
    type: UPDATE_PANIER,
    panier
  };
}

export function isPanierPayed(isPanierPayed) {
  return {
    type: IS_PANIER_PAYED,
    isPanierPayed
  };
}

export function setAccessTokenUser(token) {
  return {
    type: SET_ACCESS_TOKEN,
    token
  };
}

export function setRefreshTokenUser(token) {
  return {
    type: SET_REFRESH_TOKEN,
    token
  };
}

export function setRegister(registerUpdate) {
  return {
    type: SET_REGISTER_UPDATE,
    registerUpdate
  };
}
