
/**
 * Author: SGAT Technologies
 * https://www.sgat-technologies.com
 */

// Actions
const SET_DIFFICULTY = 'explorer/SET_DIFFICULTY';
const SET_HEIGHT = 'explorer/SET_HEIGHT';
const SET_HASHRATE = 'explorer/SET_HASHRATE';
const SET_EMISSION = 'explorer/SET_EMISSION';

// Initial state
export const initialState = {
  difficulty: null,
  height: null,
  hashrate: null,
  emission: null,
};

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_DIFFICULTY:
      return {
        ...state,
        difficulty: action.difficulty,
      };
    case SET_HEIGHT:
      return {
        ...state,
        height: action.height,
      };
    case SET_HASHRATE:
      return {
        ...state,
        hashrate: action.hashrate,
      };
    case SET_EMISSION:
      return {
        ...state,
        emission: action.emission,
      };
    default:
      return state;
  }
}

// Action Creators
export function setDifficulty(difficulty) {
  return {
    type: SET_DIFFICULTY,
    difficulty,
  };
}

export function setHeight(height) {
  return {
    type: SET_HEIGHT,
    height,
  };
}

export function setHashrate(hashrate) {
  return {
    type: SET_HASHRATE,
    hashrate,
  };
}

export function setEmission(emission) {
  return {
    type: SET_EMISSION,
    emission,
  };
}
